import './App.css';




function App() {
  return (
    <div className="App">
      <ul>
        <li><a href='/'>Home</a></li>
        <li><a href='/donate'>Donate</a></li>
        <li><a href='/about'>About Me</a></li>
      </ul>
    </div>
  );
}

export default App;
