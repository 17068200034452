import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Donate from './routes/donate';
import About from './routes/about';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql, HttpLink, ApolloLink } from '@apollo/client';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const httpLink = new HttpLink({
  uri: 'https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clfx6xvn95zow01ui5bvph2x3/master'
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODgyNTIxODksImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NsZng2eHZuOTV6b3cwMXVpNWJ2cGgyeDMvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQuZ3JhcGhjbXMuY29tLyIsInN1YiI6IjhkYWUxNzJhLTc3MzUtNDFkYS1hYWZkLTM3NTZmZDdiZTc3NCIsImp0aSI6ImNsamtscmloMzE5aXowMXRiMjV5NzljMXQifQ.Lh9fD8_XTeQp4ImCH0NoNJcjUwn_6vCertpJwohVbK_ZwH_yVm56PFP0JE5YEFY9Xi7w5-5ZVzqQ84nSrC3Z12Axcgl-W-shshmgeYuOCZIPXKFel1MffmELbimBzibkK9Z7idc29wpBDQYB1Wv5sNulGa-iMRkuSFwTQER2rVMVFB85nPAsbBgX_uMfpnYNNkieQEu6zm8pLwamDJU7dtR0kOBz46LHcLSCzaK4AcjsSHOdqPrAal_GeAvZrjzAz9Zo_QX39VQF9UWKK2t0UlKZSacWOMZFWriBfho3pmYAIYCxoUZHNscgVeeXKMo8qWUQxSOmBOtVyQiIPkEHBwmwYdyBodK_Rwbqhwet7wQjprWZ1XJIf0MrGjlfv4csZ9uZlsFEcWIVZdLgwsajY8d2IupSFpo38rGRBXNHCe0Lm6pNXwG8qctq5R76z-IK9Zytkzt9dXslyCMprIz-Ftt2iwBiF5PDU3IxsyyGkmuaz1WLwtrYfHhWID_EEZNrSvRy4TdoCz9ZJAlHv8iS9vfFnkcGq2_nFTdl9vZlhvX8d2ZpHoqZka91YvHeSU2oBjlu0LZyHQAY9Ia1hlKQCQGWH0dgJr27B1cQ4UJa4Xe94_4PJ6jS-oXN-KALiPaJveJFLbHdfLVBPmabHG6BdDawG4tmqLcmwtHpSbjYPlM',
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const GET_CONTENT = gql`
query Content {
  page(where: {slug: "home"}) {
    content {
      html
    }
  }
}`;

function Home() {
  const { loading, error, data } = useQuery(GET_CONTENT);

  if(loading) return <p>Loading...</p>;
  if(error) {
    console.log("Error: ", error);
    return <p>Error: </p>;
  }

  return(
      <div>
          <div dangerouslySetInnerHTML={{__html: data.page.content.html}} />
      </div>
  );
}


root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <BrowserRouter>
      <App />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="donate" element={<Donate />} />
        <Route path="about" element={<About />} />
      </Routes>
    </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
